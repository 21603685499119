import { createContext, useContext } from 'react';

import { 
  SCHEMA_TYPE_BRANDS, 
  SCHEMA_TYPE_DIMENSIONS, 
  SCHEMA_TYPE_COLORS, 
  SCHEMA_TYPE_MATERIALS, 
  SCHEMA_TYPE_STYLES 
} from '../util/types';

//import { is } from 'core-js/core/object';

export const ConfigurationContext = createContext();

export const ConfigurationProvider = ConfigurationContext.Provider;

export const useConfiguration = () => {
  const config = useContext(ConfigurationContext);
  // Sharetribe admin panel doesn't have all field types
  // So tweak it here if you need
  return {
    ...config,
    listing: {
      ...config.listing,
      listingFields: config.listing.listingFields.map(field => {
        const isDimensionsField = field.key === 'dimensions';

        if (isDimensionsField) {
          return {
            ...field,
            schemaType: SCHEMA_TYPE_DIMENSIONS,
          };
        }

        const isBrandField = field.key === 'brand';
        if (isBrandField) {
          return {
            ...field,
            schemaType: SCHEMA_TYPE_BRANDS,
          };
        }

        const isColorField = field.key === 'colour';
        if (isColorField) {
          return {
            ...field,
            schemaType: SCHEMA_TYPE_COLORS,
          };
        }

        const isMaterialField = field.key === 'material';
        if (isMaterialField) {
          return {
            ...field,
            schemaType: SCHEMA_TYPE_MATERIALS,
          };
        }

        const isStyleField = field.key === 'style'; 
        if (isStyleField) {
          return {
            ...field,
            schemaType: SCHEMA_TYPE_STYLES,
          };
        }

        const isShippingFeesField = field.key.includes('shipping_fees_')
        if (isShippingFeesField) {
          return {
            ...field,
            key: field.key.replace("shipping_fees_", "shippingFees.")
          };
        }

        return field;
      }),
    },
  };
};
