export function queryStringToJSON(queryString) {
  // Remove the initial '?' if it exists
  if (queryString.charAt(0) === '?') {
    queryString = queryString.slice(1);
  }

  const pairs = queryString.split('&');
  const result = {};

  pairs.forEach(pair => {
    const [key, value] = pair.split('=');
    result[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return result;
}
