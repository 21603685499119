import React, { useEffect, useRef, useState } from 'react';
import { node, string } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import FieldDimensionNumberInput from './FieldDimensionNumberInput';
import FieldNumberInput from '../FieldNumberInput/FieldNumberInput';
import ValidationError from '../ValidationError/ValidationError';

const useClickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // if we attach to the document, then the click intercepts the modal background click
    // which mounts outside of __next but inside document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

const FieldDimensionsComponent = props => {
  const { label, id, name, intl } = props;

  const [touched, setTouched] = useState([]);
  const [blurred, setBlurred] = useState(false);
  const [error, setError] = useState(false);
  const ref = useRef();

  const options = [
    {
      label: 'Dimensions.width',
      key: 'width',
    },
    {
      label: 'Dimensions.height',
      key: 'height',
    },
    {
      label: 'Dimensions.depth',
      key: 'depth',
    }
  ];

  useEffect(() => {
    if (blurred) {
      if (touched.length > 0 && Object.values(props.values ?? {}).filter(Boolean).length < 3) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [touched, blurred, props.values])

  useClickOutside(ref, () => {
    setBlurred(true);
  })

  // there's an error if: 
  // any field has been touched 
  // and the focus isn't currently inside one of the fields 
  // and all values aren't populated

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <fieldset className="p-0 border-none">
        {label ? <legend className="mb-1.5 md:mb-1 md:pb-0 font-bold text-base">{label} (cm)<span className="ml-0.5">*</span></legend> : null}
        <div className={`flex flex-row rounded-md w-fit mb-2 shadow-[0_2px_3px_0_rgba(216,220,230,0.2)] border ${error ? 'border-[#ff0000]' : 'border-[#d8dce6]'} border-solid`} ref={ref}>
          {options.map((option, index) => {
            const fieldId = `${id}.${option.key}`;
            const fieldName = `${name}.${option.key}`;
            return (
              <FieldDimensionNumberInput
                inputClass={`w-[100px] md:w-[165px] ${index === 0 ? 'rounded-l-md rounded-r-none border-r-0 border-l-0 hover:border-r-0 hover:border-l-0' : ''} rounded-none border-y-0 hover:border-y-0 ${index === options.length - 1 ? 'rounded-r-md rounded-l-none border-l-0 border-r-0 hover:border-l-0 hover:border-r-0' : ''}`}
                key={fieldId}
                onTouch={() => {
                  setBlurred(false);
                  setTouched(curr => curr.includes(option.key) ? curr : [...curr, option.key])
                }}
                id={fieldId}
                name={fieldName}
                placeholder={`${intl.formatMessage({ id: option.label })}`}
              // validate={validate}
              />
            );
          })}
        </div>
        {/* <ValidationError fieldMeta={{ touched: error, error: 'Required' }} /> */}
      </fieldset>
      <FieldNumberInput
        inputClass="shadow-[0_2px_3px_0_rgba(216,220,230,0.2)] mb-8 min-w-[240px]"
        key={`${id}.weight`}
        id={`${id}.weight`}
        name={`${name}.weight`}
        label={`${intl.formatMessage({ id: 'Dimensions.weight' })} (kg)`}
        placeholder="4kg"
      />
    </div>
  );
};

FieldDimensionsComponent.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
};

FieldDimensionsComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  label: node,
};

export default injectIntl(FieldDimensionsComponent);
