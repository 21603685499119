export const categoryOptions = [
  { option: 'Furniture', label: 'Furniture' },
  { option: 'Home Decor', label: 'Home Decor' },
  { option: 'Lighting', label: 'Lighting' },
  { option: 'Art', label: 'Art' },
  { option: 'Rugs', label: 'Rugs' },
  { option: 'Other', label: 'Other' },
];

export const subcategory1Options = [

  { option: 'Seating', label: 'Seating', category: 'furniture' },
  { option: 'Storage', label: 'Storage', category: 'furniture' },
  { option: 'Tables', label: 'Tables', category: 'furniture' },
  { option: 'Beds', label: 'Beds', category: 'furniture' },
  { option: 'Accessories', label: 'Accessories', category: 'home decor' },
  { option: 'Home Fixtures', label: 'Home Fixtures', category: 'home decor' },
  { option: 'Kitchenware', label: 'Kitchenware', category: 'home decor' },
  { option: 'Mirrors', label: 'Mirrors', category: 'home decor' },
  { option: 'Ceiling Lights', label: 'Ceiling Lights', category: 'lighting' },
  { option: 'Lamps', label: 'Lamps', category: 'lighting' },
  { option: 'Other Lighting', label: 'Other Lighting', category: 'lighting' },
  { option: 'Wall Art', label: 'Wall Art', category: 'art' },
  { option: 'Sculptures', label: 'Sculptures', category: 'art' },
  { option: 'Rugs', label: 'Rugs', category: 'rugs' },
  { option: 'Textiles', label: 'Textiles', category: 'rugs' },
  { option: 'Other', label: 'Other', category: 'other' },
];

export const subcategory2Options = [

  // Furniture - Seating
  { option: 'Armchairs', label: 'Armchairs', subcategory1: 'seating' },
  { option: 'Dining Chairs', label: 'Dining Chairs', subcategory1: 'seating' },
  { option: 'Sofas', label: 'Sofas', subcategory1: 'seating' },
  { option: 'Stools', label: 'Stools', subcategory1: 'seating' },
  { option: 'Lounge Chairs', label: 'Lounge Chairs', subcategory1: 'seating' },
  { option: 'Hall Chairs', label: 'Hall Chairs', subcategory1: 'seating' },
  { option: 'Benches', label: 'Benches', subcategory1: 'seating' },
  { option: 'Occasional Chairs', label: 'Occasional Chairs', subcategory1: 'seating' },
  { option: 'Office Chairs', label: 'Office Chairs', subcategory1: 'seating' },
  { option: 'Rocking Chairs', label: 'Rocking Chairs', subcategory1: 'seating' },
  { option: 'Other Seating', label: 'Other Seating', subcategory1: 'seating' },
  { option: 'Other', label: 'Other', subcategory1: 'seating' },

  // Furniture - Storage
  { option: 'Baskets', label: 'Baskets', subcategory1: 'storage' },
  { option: 'Bookshelves', label: 'Bookshelves', subcategory1: 'storage' },
  { option: 'Boxes', label: 'Boxes', subcategory1: 'storage' },
  { option: 'Cabinets', label: 'Cabinets', subcategory1: 'storage' },
  { option: 'Coat Racks', label: 'Coat Racks', subcategory1: 'storage' },
  { option: 'Chest of Drawers', label: 'Chest of Drawers', subcategory1: 'storage' },
  { option: 'Cupboards', label: 'Cupboards', subcategory1: 'storage' },
  { option: 'Media Units', label: 'Media Units', subcategory1: 'storage' },
  { option: 'Magazine Racks', label: 'Magazine Racks', subcategory1: 'storage' },
  { option: 'Secretary Desks', label: 'Secretary Desks', subcategory1: 'storage' },
  { option: 'Shelves', label: 'Shelves', subcategory1: 'storage' },
  { option: 'Sideboards', label: 'Sideboards', subcategory1: 'storage' },
  { option: 'Trolleys', label: 'Trolleys', subcategory1: 'storage' },
  { option: 'Trunks', label: 'Trunks', subcategory1: 'storage' },
  { option: 'Wardrobes', label: 'Wardrobes', subcategory1: 'storage' },
  { option: 'Other Storage', label: 'Other Storage', subcategory1: 'storage' },
  { option: 'Other', label: 'Other', subcategory1: 'storage' },

  // Furniture - Tables
  { option: 'Bedside Tables', label: 'Bedside Tables', subcategory1: 'tables' },
  { option: 'Coffee Tables', label: 'Coffee Tables', subcategory1: 'tables' },
  { option: 'Consoles', label: 'Consoles', subcategory1: 'tables' },
  { option: 'Desks', label: 'Desks', subcategory1: 'tables' },
  { option: 'Dining Tables', label: 'Dining Tables', subcategory1: 'tables' },
  { option: 'Dining Tables & Chairs', label: 'Dining Tables & Chairs', subcategory1: 'tables' },
  { option: 'Dressing Tables', label: 'Dressing Tables', subcategory1: 'tables' },
  { option: 'Side Tables', label: 'Side Tables', subcategory1: 'tables' },
  { option: 'Drop Leaf Tables', label: 'Drop Leaf Tables', subcategory1: 'tables' },
  { option: 'Workbenches', label: 'Workbenches', subcategory1: 'tables' },
  { option: 'Other Tables', label: 'Other Tables', subcategory1: 'tables' },
  { option: 'Other', label: 'Other', subcategory1: 'tables' },

  // Furniture - Beds
  { option: 'Bed Frames', label: 'Bed Frames', subcategory1: 'beds' },
  { option: 'Headboards', label: 'Headboards', subcategory1: 'beds' },
  { option: 'Other Beds', label: 'Other Beds', subcategory1: 'beds' },
  { option: 'Other', label: 'Other', subcategory1: 'beds' },

  // Home Decor - Accessories
  { option: 'Ashtrays', label: 'Ashtrays', subcategory1: 'accessories' },
  { option: 'Books and Magazines', label: 'Books and Magazines', subcategory1: 'accessories' },
  { option: 'Candle Holders', label: 'Candle Holders', subcategory1: 'accessories' },
  { option: 'Clocks', label: 'Clocks', subcategory1: 'accessories' },
  { option: 'Collectibles', label: 'Collectibles', subcategory1: 'accessories' },
  { option: 'Columns and Pedestals', label: 'Columns and Pedestals', subcategory1: 'accessories' },
  { option: 'Mannequins', label: 'Mannequins', subcategory1: 'accessories' },
  { option: 'Other Objects', label: 'Other Objects', subcategory1: 'accessories' },
  { option: 'Desk Accessories', label: 'Desk Accessories', subcategory1: 'accessories' },
  { option: 'Picture Frames', label: 'Picture Frames', subcategory1: 'accessories' },
  { option: 'Room Dividers', label: 'Room Dividers', subcategory1: 'accessories' },
  { option: 'Pots & Planters', label: 'Pots & Planters', subcategory1: 'accessories' },
  { option: 'Toys', label: 'Toys', subcategory1: 'accessories' },
  { option: 'Signs', label: 'Signs', subcategory1: 'accessories' },
  { option: 'Vases', label: 'Vases', subcategory1: 'accessories' },
  { option: 'Other Accessories', label: 'Other Accessories', subcategory1: 'accessories' },
  { option: 'Other', label: 'Other', subcategory1: 'accessories' },

  // Home Decor - Home Fixtures
  { option: 'Doors', label: 'Doors', subcategory1: 'home fixtures' },
  { option: 'Fireplace, Surrounds and Inserts', label: 'Fireplace, Surrounds and Inserts', subcategory1: 'home fixtures' },
  { option: 'Windows', label: 'Windows', subcategory1: 'home fixtures' },
  { option: 'Flooring', label: 'Flooring', subcategory1: 'home fixtures' },
  { option: 'Other Home Fixtures', label: 'Other Home Fixtures', subcategory1: 'home fixtures' },
  { option: 'Other', label: 'Other', subcategory1: 'home fixtures' },

  // Home Decor - Kitchenware
  { option: 'Bakeware', label: 'Bakeware', subcategory1: 'kitchenware' },
  { option: 'Bowls', label: 'Bowls', subcategory1: 'kitchenware' },
  { option: 'Cutlery', label: 'Cutlery', subcategory1: 'kitchenware' },
  { option: 'Glassware', label: 'Glassware', subcategory1: 'kitchenware' },
  { option: 'Jugs, Jars and Tins', label: 'Jugs, Jars and Tins', subcategory1: 'kitchenware' },
  { option: 'Kitchenalia', label: 'Kitchenalia', subcategory1: 'kitchenware' },
  { option: 'Plates', label: 'Plates', subcategory1: 'kitchenware' },
  { option: 'Serving Dishes', label: 'Serving Dishes', subcategory1: 'kitchenware' },
  { option: 'Silverware', label: 'Silverware', subcategory1: 'kitchenware' },
  { option: 'Tea and Coffee', label: 'Tea and Coffee', subcategory1: 'kitchenware' },
  { option: 'Ceramic Crockery', label: 'Ceramic Crockery', subcategory1: 'kitchenware' },
  { option: 'Trays', label: 'Trays', subcategory1: 'kitchenware' },
  { option: 'Other Kitchenware', label: 'Other Kitchenware', subcategory1: 'kitchenware' },
  { option: 'Other', label: 'Other', subcategory1: 'kitchenware' },

  // Home Decor - Mirrors
  { option: 'Floor Mirrors', label: 'Floor Mirrors', subcategory1: 'mirrors' },
  { option: 'Hand Mirrors', label: 'Hand Mirrors', subcategory1: 'mirrors' },
  { option: 'Table Mirrors', label: 'Table Mirrors', subcategory1: 'mirrors' },
  { option: 'Wall Mirrors', label: 'Wall Mirrors', subcategory1: 'mirrors' },
  { option: 'Other Mirrors', label: 'Other Mirrors', subcategory1: 'mirrors' },
  { option: 'Other', label: 'Other', subcategory1: 'mirrors' },

  // Lighting - Ceiling Lights
  { option: 'Chandelier', label: 'Chandelier', subcategory1: 'ceiling lights' },
  { option: 'Pendants', label: 'Pendants', subcategory1: 'ceiling lights' },
  { option: 'Other Ceiling Lights', label: 'Other Ceiling Lights', subcategory1: 'ceiling lights' },
  { option: 'Other', label: 'Other', subcategory1: 'ceiling lights' },

  // Lighting - Lamps
  { option: 'Floors Lamps', label: 'Floors Lamps', subcategory1: 'lamps' },
  { option: 'Lamp Shades', label: 'Lamp Shades', subcategory1: 'lamps' },
  { option: 'Table Lamps', label: 'Table Lamps', subcategory1: 'lamps' },
  { option: 'Other Lamps', label: 'Other Lamps', subcategory1: 'lamps' },
  { option: 'Other', label: 'Other', subcategory1: 'lamps' },

  // Lighting - Other Lighting
  { option: 'Wall Lights', label: 'Wall Lights', subcategory1: 'other lighting' },
  { option: 'Other Lighting', label: 'Other Lighting', subcategory1: 'other lighting' },
  { option: 'Other', label: 'Other', subcategory1: 'other lighting' },

  // Art - Wall Art
  { option: 'Paintings', label: 'Paintings', subcategory1: 'wall art' },
  { option: 'Posters', label: 'Posters', subcategory1: 'wall art' },
  { option: 'Photography', label: 'Photography', subcategory1: 'wall art' },
  { option: 'Fine Art Prints', label: 'Fine Art Prints', subcategory1: 'wall art' },
  { option: 'Other Wall Decor', label: 'Other Wall Decor', subcategory1: 'wall art' },
  { option: 'Tapestries', label: 'Tapestries', subcategory1: 'wall art' },
  { option: 'Other Wall Art', label: 'Other Wall Art', subcategory1: 'wall art' },
  { option: 'Other', label: 'Other', subcategory1: 'wall art' },

  // Art - Sculptures
  { option: 'Wall Sculptures', label: 'Wall Sculptures', subcategory1: 'sculptures' },
  { option: 'Free Standing', label: 'Free Standing', subcategory1: 'sculptures' },
  { option: 'Other Sculptures', label: 'Other Sculptures', subcategory1: 'sculptures' },
  { option: 'Other', label: 'Other', subcategory1: 'sculptures' },

  // Rugs - Rugs
  { option: 'Area Rugs', label: 'Area Rugs', subcategory1: 'rugs' },
  { option: 'Runners', label: 'Runners', subcategory1: 'rugs' },
  { option: 'Accents and Throw Rugs', label: 'Accents and Throw Rugs', subcategory1: 'rugs' },
  { option: 'Hides and Skins', label: 'Hides and Skins', subcategory1: 'rugs' },
  { option: 'Other Rugs', label: 'Other Rugs', subcategory1: 'rugs' },
  { option: 'Other', label: 'Other', subcategory1: 'rugs' },

  // Rugs - Textiles
  { option: 'Cushions', label: 'Cushions', subcategory1: 'textiles' },
  { option: 'Throws', label: 'Throws', subcategory1: 'textiles' },
  { option: 'Other Textiles', label: 'Other Textiles', subcategory1: 'textiles' },
  { option: 'Other', label: 'Other', subcategory1: 'textiles' },
];

export const materialOptions = [
  { option: 'Acrylic', label: 'Acrylic' },
  { option: 'Alder', label: 'Alder' },
  { option: 'Aluminium', label: 'Aluminium' },
  { option: 'Bakelite', label: 'Bakelite' },
  { option: 'Bamboo', label: 'Bamboo' },
  { option: 'Beech', label: 'Beech' },
  { option: 'Bentwood', label: 'Bentwood' },
  { option: 'Bone', label: 'Bone' },
  { option: 'Brass', label: 'Brass' },
  { option: 'Bronze', label: 'Bronze' },
  { option: 'Cane', label: 'Cane' },
  { option: 'Canvas', label: 'Canvas' },
  { option: 'Cast Iron', label: 'Cast Iron' },
  { option: 'Cedar', label: 'Cedar' },
  { option: 'Ceramic', label: 'Ceramic' },
  { option: 'Cherry Wood', label: 'Cherry Wood' },
  { option: 'Chestnut', label: 'Chestnut' },
  { option: 'Chrome', label: 'Chrome' },
  { option: 'Concrete', label: 'Concrete' },
  { option: 'Copper', label: 'Copper' },
  { option: 'Cotton', label: 'Cotton' },
  { option: 'Earthenware', label: 'Earthenware' },
  { option: 'Enamelled Sheet', label: 'Enamelled Sheet' },
  { option: 'Ebony', label: 'Ebony' },
  { option: 'Elm', label: 'Elm' },
  { option: 'Enamel', label: 'Enamel' },
  { option: 'Feather', label: 'Feather' },
  { option: 'Fibreglass', label: 'Fibreglass' },
  { option: 'Formica', label: 'Formica' },
  { option: 'Glass, Crystal', label: 'Glass, Crystal' },
  { option: 'Gold', label: 'Gold' },
  { option: 'Granite', label: 'Granite' },
  { option: 'Iron', label: 'Iron' },
  { option: 'Ivory', label: 'Ivory' },
  { option: 'Larch', label: 'Larch' },
  { option: 'Laurel', label: 'Laurel' },
  { option: 'Leather', label: 'Leather' },
  { option: 'Leatherette', label: 'Leatherette' },
  { option: 'Linen', label: 'Linen' },
  { option: 'Mahogany', label: 'Mahogany' },
  { option: 'Maple', label: 'Maple' },
  { option: 'Marble', label: 'Marble' },
  { option: 'Mirror', label: 'Mirror' },
  { option: 'Metal', label: 'Metal' },
  { option: 'Nickel', label: 'Nickel' },
  { option: 'Opaline', label: 'Opaline' },
  { option: 'Oak', label: 'Oak' },
  { option: 'Paint', label: 'Paint' },
  { option: 'Paper', label: 'Paper' },
  { option: 'Pewter', label: 'Pewter' },
  { option: 'Pine', label: 'Pine' },
  { option: 'Plaster', label: 'Plaster' },
  { option: 'Plastic', label: 'Plastic' },
  { option: 'Plexiglass', label: 'Plexiglass' },
  { option: 'Porcelain', label: 'Porcelain' },
  { option: 'Pottery', label: 'Pottery' },
  { option: 'Rattan', label: 'Rattan' },
  { option: 'Reclaimed Wood', label: 'Reclaimed Wood' },
  { option: 'Rosewood', label: 'Rosewood' },
  { option: 'Rubber', label: 'Rubber' },
  { option: 'Satin Wood', label: 'Satin Wood' },
  { option: 'Shell', label: 'Shell' },
  { option: 'Silk', label: 'Silk' },
  { option: 'Silver', label: 'Silver' },
  { option: 'Silver Plated', label: 'Silver Plated' },
  { option: 'Skin', label: 'Skin' },
  { option: 'Steel', label: 'Steel' },
  { option: 'Stone', label: 'Stone' },
  { option: 'Stoneware', label: 'Stoneware' },
  { option: 'Sycamore', label: 'Sycamore' },
  { option: 'Teak', label: 'Teak' },
  { option: 'Tin', label: 'Tin' },
  { option: 'Travertine', label: 'Travertine' },
  { option: 'Tweed', label: 'Tweed' },
  { option: 'Velvet', label: 'Velvet' },
  { option: 'Vinyl', label: 'Vinyl' },
  { option: 'Walnut', label: 'Walnut' },
  { option: 'Watercolour', label: 'Watercolour' },
  { option: 'Wicker', label: 'Wicker' },
  { option: 'Wool', label: 'Wool' },
  { option: 'Zinc', label: 'Zinc' },
  { option: 'Other Fabric and Textiles', label: 'Other Fabric and Textiles' },
  { option: 'Other Metal', label: 'Other Metal' },
  { option: 'Other Wood', label: 'Other Wood' },
  { option: 'Other', label: 'Other' },
];

export const styleOptions = [
  { option: 'American Colonial', label: 'American Colonial' },
  { option: 'African', label: 'African' },
  { option: 'Art Deco', label: 'Art Deco' },
  { option: 'Art Nouveau', label: 'Art Nouveau' },
  { option: 'Asian', label: 'Asian' },
  { option: 'Bohemian', label: 'Bohemian' },
  { option: 'Colonial', label: 'Colonial' },
  { option: 'Contemporary', label: 'Contemporary' },
  { option: 'French', label: 'French' },
  { option: 'Gothic', label: 'Gothic' },
  { option: 'Hollywood Regency', label: 'Hollywood Regency' },
  { option: 'Industrial', label: 'Industrial' },
  { option: 'Italian', label: 'Italian' },
  { option: 'Japanese', label: 'Japanese' },
  { option: 'Mediterranean', label: 'Mediterranean' },
  { option: 'Mid-Century Modern', label: 'Mid-Century Modern' },
  { option: 'Minimalism', label: 'Minimalism' },
  { option: 'Modern', label: 'Modern' },
  { option: 'Moroccan', label: 'Moroccan' },
  { option: 'Nordic', label: 'Nordic' },
  { option: 'Retro', label: 'Retro' },
  { option: 'Rustic', label: 'Rustic' },
  { option: 'Scandinavian', label: 'Scandinavian' },
  { option: 'Shabby Chic', label: 'Shabby Chic' },
  { option: 'Southwestern', label: 'Southwestern' },
  { option: 'Classic', label: 'Classic' },
  { option: 'Victorian', label: 'Victorian' },
  { option: 'Vintage', label: 'Vintage' },
  { option: 'Other', label: 'Other' },
];

export const colourOptions = [
  { option: 'Beige', label: 'Beige' },
  { option: 'Black', label: 'Black' },
  { option: 'Blue', label: 'Blue' },
  { option: 'Brown', label: 'Brown' },
  { option: 'Gold', label: 'Gold' },
  { option: 'Green', label: 'Green' },
  { option: 'Grey', label: 'Grey' },
  { option: 'Orange', label: 'Orange' },
  { option: 'Pink', label: 'Pink' },
  { option: 'Purple', label: 'Purple' },
  { option: 'Red', label: 'Red' },
  { option: 'Silver', label: 'Silver' },
  { option: 'Transparent', label: 'Transparent' },
  { option: 'White', label: 'White' },
  { option: 'Yellow', label: 'Yellow' },
  { option: 'Multicolour', label: 'Multicolour' },
  { option: 'Other', label: 'Other' },
];

export const brandOptions = [
  { option: 'ARP', label: 'ARP' },
  { option: 'Aalto', label: 'Aalto' },
  { option: 'Aarnio', label: 'Aarnio' },
  { option: 'Abraham', label: 'Abraham' },
  { option: 'Adnet', label: 'Adnet' },
  { option: 'Agnoli', label: 'Agnoli' },
  { option: 'Albini', label: 'Albini' },
  { option: 'Andersen', label: 'Andersen' },
  { option: 'Arad', label: 'Arad' },
  { option: 'Archizoom Associati', label: 'Archizoom Associati' },
  { option: 'Arnal', label: 'Arnal' },
  { option: 'Arteluce', label: 'Arteluce' },
  { option: 'Artemide', label: 'Artemide' },
  { option: 'Asti', label: 'Asti' },
  { option: 'Audoux et Minnet', label: 'Audoux et Minnet' },
  { option: 'Aulenti', label: 'Aulenti' },
  { option: 'Barbi', label: 'Barbi' },
  { option: 'Barovier & Toso', label: 'Barovier & Toso' },
  { option: 'Belarti', label: 'Belarti' },
  { option: 'Bellini', label: 'Bellini' },
  { option: 'Belotti', label: 'Belotti' },
  { option: 'Berthier', label: 'Berthier' },
  { option: 'Bertoia', label: 'Bertoia' },
  { option: 'Biny', label: 'Biny' },
  { option: 'Blin', label: 'Blin' },
  { option: 'Boeri', label: 'Boeri' },
  { option: 'Borsani', label: 'Borsani' },
  { option: 'Botta', label: 'Botta' },
  { option: 'Bouroullec', label: 'Bouroullec' },
  { option: 'Braakman', label: 'Braakman' },
  { option: 'Breuer', label: 'Breuer' },
  { option: 'Brotto', label: 'Brotto' },
  { option: 'Burke', label: 'Burke' },
  { option: 'Cadestin', label: 'Cadestin' },
  { option: 'Cadovius', label: 'Cadovius' },
  { option: 'Caillette', label: 'Caillette' },
  { option: 'Capron', label: 'Capron' },
  { option: 'Cardin', label: 'Cardin' },
  { option: 'Castelli', label: 'Castelli' },
  { option: 'Castiglioni', label: 'Castiglioni' },
  { option: 'Castiglioni', label: 'Castiglioni' },
  { option: 'Cazenave', label: 'Cazenave' },
  { option: 'Chan Kwok', label: 'Chan Kwok' },
  { option: 'Chapo', label: 'Chapo' },
  { option: 'Christin', label: 'Christin' },
  { option: 'Citterio', label: 'Citterio' },
  { option: 'Colombo', label: 'Colombo' },
  { option: 'Conran', label: 'Conran' },
  { option: 'Cordemeijer', label: 'Cordemeijer' },
  { option: 'Cox', label: 'Cox' },
  { option: 'Crespi', label: 'Crespi' },
  { option: 'Cruège', label: 'Cruège' },
  { option: 'D\'Urbino', label: 'D\'Urbino' },
  { option: 'De Carli', label: 'De Carli' },
  { option: 'De Coene', label: 'De Coene' },
  { option: 'De Sede', label: 'De Sede' },
  { option: 'Disderot', label: 'Disderot' },
  { option: 'Ducaroy', label: 'Ducaroy' },
  { option: 'Dudouyt', label: 'Dudouyt' },
  { option: 'Dumas', label: 'Dumas' },
  { option: 'Eames', label: 'Eames' },
  { option: 'Eichenberger', label: 'Eichenberger' },
  { option: 'Enzo', label: 'Enzo' },
  { option: 'Ercolani', label: 'Ercolani' },
  { option: 'Erton', label: 'Erton' },
  { option: 'Fase', label: 'Fase' },
  { option: 'Fermigier', label: 'Fermigier' },
  { option: 'Ferraud', label: 'Ferraud' },
  { option: 'Fichtel', label: 'Fichtel' },
  { option: 'Fontana Arte', label: 'Fontana Arte' },
  { option: 'Frattini', label: 'Frattini' },
  { option: 'Frydman', label: 'Frydman' },
  { option: 'G-Plan', label: 'G-Plan' },
  { option: 'Gabriel', label: 'Gabriel' },
  { option: 'Gallotti', label: 'Gallotti' },
  { option: 'Gammelgaard', label: 'Gammelgaard' },
  { option: 'Gascoin', label: 'Gascoin' },
  { option: 'Gau', label: 'Gau' },
  { option: 'Gautier Delaye', label: 'Gautier Delaye' },
  { option: 'Gecchelin', label: 'Gecchelin' },
  { option: 'Ghyczy', label: 'Ghyczy' },
  { option: 'Gingembre', label: 'Gingembre' },
  { option: 'Grassi', label: 'Grassi' },
  { option: 'Gray', label: 'Gray' },
  { option: 'Guariche', label: 'Guariche' },
  { option: 'Guhl', label: 'Guhl' },
  { option: 'Guido Drocco & Franco Mello', label: 'Guido Drocco & Franco Mello' },
  { option: 'Guillerme et Chambron', label: 'Guillerme et Chambron' },
  { option: 'Guzzini', label: 'Guzzini' },
  { option: 'Hammerborg', label: 'Hammerborg' },
  { option: 'Hansen', label: 'Hansen' },
  { option: 'Harcourt', label: 'Harcourt' },
  { option: 'Held', label: 'Held' },
  { option: 'Hendrickx', label: 'Hendrickx' },
  { option: 'Henningsen', label: 'Henningsen' },
  { option: 'Herbst', label: 'Herbst' },
  { option: 'Hierominus', label: 'Hierominus' },
  { option: 'Hitier', label: 'Hitier' },
  { option: 'Hurka', label: 'Hurka' },
  { option: 'Hvidt', label: 'Hvidt' },
  { option: 'Hänninen', label: 'Hänninen' },
  { option: 'Introini', label: 'Introini' },
  { option: 'Iversen', label: 'Iversen' },
  { option: 'Jacob', label: 'Jacob' },
  { option: 'Jacober', label: 'Jacober' },
  { option: 'Jacobsen', label: 'Jacobsen' },
  { option: 'Jakobsson', label: 'Jakobsson' },
  { option: 'Jalk', label: 'Jalk' },
  { option: 'Jeanneret', label: 'Jeanneret' },
  { option: 'Jiroutek', label: 'Jiroutek' },
  { option: 'Jonsson', label: 'Jonsson' },
  { option: 'Jouve', label: 'Jouve' },
  { option: 'Juhl', label: 'Juhl' },
  { option: 'Jumo', label: 'Jumo' },
  { option: 'Jun', label: 'Jun' },
  { option: 'Kalff', label: 'Kalff' },
  { option: 'Kastholm', label: 'Kastholm' },
  { option: 'Ketoff', label: 'Ketoff' },
  { option: 'Kho Liang', label: 'Kho Liang' },
  { option: 'Kinsman', label: 'Kinsman' },
  { option: 'Kirkman Cole', label: 'Kirkman Cole' },
  { option: 'Kjaernulf', label: 'Kjaernulf' },
  { option: 'Klint', label: 'Klint' },
  { option: 'Knoll', label: 'Knoll' },
  { option: 'Koefoed', label: 'Koefoed' },
  { option: 'Kogl', label: 'Kogl' },
  { option: 'Kramer', label: 'Kramer' },
  { option: 'Kristiansen', label: 'Kristiansen' },
  { option: 'Lacca', label: 'Lacca' },
  { option: 'Landault', label: 'Landault' },
  { option: 'Lange', label: 'Lange' },
  { option: 'Laporte', label: 'Laporte' },
  { option: 'Larsson', label: 'Larsson' },
  { option: 'Le Corbusier', label: 'Le Corbusier' },
  { option: 'Lecal', label: 'Lecal' },
  { option: 'Lecoq', label: 'Lecoq' },
  { option: 'Legras', label: 'Legras' },
  { option: 'Liaigre', label: 'Liaigre' },
  { option: 'Linneberg', label: 'Linneberg' },
  { option: 'Loewy', label: 'Loewy' },
  { option: 'Loos', label: 'Loos' },
  { option: 'Magistretti', label: 'Magistretti' },
  { option: 'Mahey', label: 'Mahey' },
  { option: 'Maison Bagues', label: 'Maison Bagues' },
  { option: 'Maison Charles', label: 'Maison Charles' },
  { option: 'Maison Jansen', label: 'Maison Jansen' },
  { option: 'Malarmey', label: 'Malarmey' },
  { option: 'Mangiarotti', label: 'Mangiarotti' },
  { option: 'Marais', label: 'Marais' },
  { option: 'Mari', label: 'Mari' },
  { option: 'Martin', label: 'Martin' },
  { option: 'Martinelli', label: 'Martinelli' },
  { option: 'Massonet', label: 'Massonet' },
  { option: 'Massoni', label: 'Massoni' },
  { option: 'Mategot', label: 'Mategot' },
  { option: 'Mathieu', label: 'Mathieu' },
  { option: 'Maurer', label: 'Maurer' },
  { option: 'Maville', label: 'Maville' },
  { option: 'Max', label: 'Max' },
  { option: 'Mazza', label: 'Mazza' },
  { option: 'Memphis Milano', label: 'Memphis Milano' },
  { option: 'Mies Van Der Rohe', label: 'Mies Van Der Rohe' },
  { option: 'Mogensen', label: 'Mogensen' },
  { option: 'Monnet', label: 'Monnet' },
  { option: 'Monpoix', label: 'Monpoix' },
  { option: 'Morrison', label: 'Morrison' },
  { option: 'Morrisson', label: 'Morrisson' },
  { option: 'Mortier', label: 'Mortier' },
  { option: 'Motte', label: 'Motte' },
  { option: 'Mouille', label: 'Mouille' },
  { option: 'Mourgue', label: 'Mourgue' },
  { option: 'Mourgue', label: 'Mourgue' },
  { option: 'Mucchi', label: 'Mucchi' },
  { option: 'Møller', label: 'Møller' },
  { option: 'Nason', label: 'Nason' },
  { option: 'Navone', label: 'Navone' },
  { option: 'Nelson', label: 'Nelson' },
  { option: 'Noguchi', label: 'Noguchi' },
  { option: 'Nouvel', label: 'Nouvel' },
  { option: 'Offredi', label: 'Offredi' },
  { option: 'Olsen', label: 'Olsen' },
  { option: 'Panton', label: 'Panton' },
  { option: 'Paolozzi', label: 'Paolozzi' },
  { option: 'Parisi', label: 'Parisi' },
  { option: 'Parry', label: 'Parry' },
  { option: 'Pauchard', label: 'Pauchard' },
  { option: 'Paulin', label: 'Paulin' },
  { option: 'Pelletier', label: 'Pelletier' },
  { option: 'Pergay', label: 'Pergay' },
  { option: 'Perriand', label: 'Perriand' },
  { option: 'Perzel', label: 'Perzel' },
  { option: 'Pesce', label: 'Pesce' },
  { option: 'Philippon', label: 'Philippon' },
  { option: 'Piretti', label: 'Piretti' },
  { option: 'Piva', label: 'Piva' },
  { option: 'Platner', label: 'Platner' },
  { option: 'Poisson', label: 'Poisson' },
  { option: 'Pollock', label: 'Pollock' },
  { option: 'Ponti', label: 'Ponti' },
  { option: 'Prouve', label: 'Prouve' },
  { option: 'Pré', label: 'Pré' },
  { option: 'Quarante', label: 'Quarante' },
  { option: 'Quinet', label: 'Quinet' },
  { option: 'Quistgaard', label: 'Quistgaard' },
  { option: 'Rams', label: 'Rams' },
  { option: 'Reggiani', label: 'Reggiani' },
  { option: 'Relling', label: 'Relling' },
  { option: 'Richard', label: 'Richard' },
  { option: 'Rietveld', label: 'Rietveld' },
  { option: 'Rietveld', label: 'Rietveld' },
  { option: 'Rinaldi', label: 'Rinaldi' },
  { option: 'Risom', label: 'Risom' },
  { option: 'Rispal', label: 'Rispal' },
  { option: 'Rizzo', label: 'Rizzo' },
  { option: 'Rohé Noordwolde', label: 'Rohé Noordwolde' },
  { option: 'Rowland', label: 'Rowland' },
  { option: 'Russell', label: 'Russell' },
  { option: 'Ruth', label: 'Ruth' },
  { option: 'Saarinen', label: 'Saarinen' },
  { option: 'Sarfatti', label: 'Sarfatti' },
  { option: 'Sauze', label: 'Sauze' },
  { option: 'Scarpa', label: 'Scarpa' },
  { option: 'Scolari', label: 'Scolari' },
  { option: 'Sottsass', label: 'Sottsass' },
  { option: 'Stam', label: 'Stam' },
  { option: 'Starck', label: 'Starck' },
  { option: 'Stegner', label: 'Stegner' },
  { option: 'Stilnovo', label: 'Stilnovo' },
  { option: 'Stoppino', label: 'Stoppino' },
  { option: 'Strinning', label: 'Strinning' },
  { option: 'Suman', label: 'Suman' },
  { option: 'Sørensen', label: 'Sørensen' },
  { option: 'Tabacoff', label: 'Tabacoff' },
  { option: 'Tallon', label: 'Tallon' },
  { option: 'Tapiovaara', label: 'Tapiovaara' },
  { option: 'Thonet', label: 'Thonet' },
  { option: 'Thonet', label: 'Thonet' },
  { option: 'Thore', label: 'Thore' },
  { option: 'Tikamoon', label: 'Tikamoon' },
  { option: 'Tura', label: 'Tura' },
  { option: 'Tynell', label: 'Tynell' },
  { option: 'USM Haller', label: 'USM Haller' },
  { option: 'Urquiola', label: 'Urquiola' },
  { option: 'Van Der Rohe', label: 'Van Der Rohe' },
  { option: 'Van Severen', label: 'Van Severen' },
  { option: 'Van Sliedregt', label: 'Van Sliedregt' },
  { option: 'Van Teeffelen', label: 'Van Teeffelen' },
  { option: 'Vandel', label: 'Vandel' },
  { option: 'Vedel', label: 'Vedel' },
  { option: 'Vervoordt', label: 'Vervoordt' },
  { option: 'Visser', label: 'Visser' },
  { option: 'Vodder', label: 'Vodder' },
  { option: 'Volak', label: 'Volak' },
  { option: 'Wabbes', label: 'Wabbes' },
  { option: 'Wanscher', label: 'Wanscher' },
  { option: 'Wegner', label: 'Wegner' },
  { option: 'Wikkelso', label: 'Wikkelso' },
  { option: 'Yanagi', label: 'Yanagi' },
  { option: 'Zalszupin', label: 'Zalszupin' },
  { option: 'Zanuso', label: 'Zanuso' },
  { option: 'Zublena', label: 'Zublena' },
  { option: 'Other', label: 'Other' },
];

export const conditionOptions = [
  { option: 'Excellent', label: 'Excellent' },
  { option: 'Good', label: 'Good' },
  { option: 'Average', label: 'Average' },
  { option: 'Other', label: 'Other' },
];