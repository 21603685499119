import React, { useState, useEffect, useRef } from 'react';
import css from './AutoCompleteField.module.css';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from 'use-debounce';
import ValidationError from '../ValidationError/ValidationError';
import classNames from 'classnames';


const useClickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // if we attach to the document, then the click intercepts the modal background click
    // which mounts outside of __next but inside document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

const AutoCompleteField = props => {
  const {
    required,
    suggestions,
    fullInfoSuggestions,
    initialValue,
    onChangeValue,
    placeholder,
    labelPropName,
    searchForAny,
    //for async results
    loadingResults,
    setLoadingResults,
    asyncGetOptions,
    startSearchLetter,
    handleNotFoundValue,
    showOptionForNotFoundValue,
  } = props;

  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState(initialValue[labelPropName] ?? '');

  const [focussed, setFocussed] = useState(false);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);

  const [debounceValue] = useDebounce(input, 500);
  const ref = useRef();

  useClickOutside(ref, () => {
    setTouched(true);
    setIsShow(false);
  });

  useEffect(() => {
    if (focussed && touched && required && !input) {
      setError(true);
    } else if (focussed && touched && required && input) {
      setError(false);
    }
  }, [focussed, touched, input, required]);

  useEffect(() => {
    if (input !== initialValue[labelPropName] && setLoadingResults) {
      asyncGetOptions(input).then(resp => {
        const newFilteredSuggestions = resp.filter(suggestion => {
          if (input.length < 3) {
            return suggestion.toLowerCase().startsWith(input.toLowerCase());
          } else {
            return suggestion.toLowerCase().includes(input.toLowerCase());
          }
        });
        setActive(0);
        setFiltered(newFilteredSuggestions);
        setIsShow(true);
      });
    }
  }, [debounceValue]);

  const onChange = e => {
    const input = e.currentTarget.value;

    if (setLoadingResults) {
      setIsShow(true);
      setLoadingResults(true);
      // const newFilteredSuggestions = suggestions.filter(
      //     suggestion =>
      //       suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
      //   );
      //   setActive(0);
      //   setFiltered(newFilteredSuggestions);
      //   setIsShow(true);
      setInput(e.currentTarget.value);
    } else {
      const newFilteredSuggestions = suggestions.filter(suggestion => {
        if (input.length < 3 && !searchForAny) {
          return suggestion.toLowerCase().startsWith(input.toLowerCase());
        } else {
          return suggestion.toLowerCase().includes(input.toLowerCase());
        }
      });
      setActive(0);
      setFiltered(newFilteredSuggestions);
      setIsShow(true);
      setInput(e.currentTarget.value);

      if (showOptionForNotFoundValue && newFilteredSuggestions.length === 0) {
        setActive(0);
        setFiltered([`+ Add ${e.currentTarget.value}`]);
        setIsShow(true);
        setInput(e.currentTarget.value);
        // handleNotFoundValue(e.currentTarget.value);
      }
    }

    if (!input.length) {
      onChangeValue(input);
    }
    
  };

  const onClick = e => {
    setActive(0);
    setFiltered([]);
    setIsShow(false);
    const foundOption = fullInfoSuggestions.find(
      s => s[labelPropName] === e.currentTarget.innerText
    );
    if (showOptionForNotFoundValue && !foundOption) {
      onChangeValue({
        key: e.currentTarget.innerText.replace('+ Add ', ''),
        label: e.currentTarget.innerText.replace('+ Add ', ''),
      });
      setInput(e.currentTarget.innerText.replace('+ Add ', ''));
    } else {
      onChangeValue(foundOption);
      setInput(e.currentTarget.innerText);
    }
  };

  const onFocus = () => {
    const newFilteredSuggestions = suggestions.filter(suggestion => {
      return suggestion.toLowerCase().includes(input.toLowerCase());
    });
    setFocussed(true);
    setActive(0);
    setFiltered(newFilteredSuggestions);
    setIsShow(true);
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      // enter key
      e.stopPropagation();
      setActive(0);
      setIsShow(false);
      const foundOption = fullInfoSuggestions.find(s => s[labelPropName] === filtered[active]);
      if (showOptionForNotFoundValue && !foundOption) {
        onChangeValue({
          key: e.currentTarget.value.replace('+ Add ', ''),
          label: e.currentTarget.value.replace('+ Add ', ''),
        });
        setInput(e.currentTarget.value.replace('+ Add ', ''));
      } else {
        onChangeValue(foundOption);
        setInput(filtered[active]);
      }
    } else if (e.keyCode === 38) {
      // up arrow
      return active === 0 ? null : setActive(active - 1);
    } else if (e.keyCode === 40) {
      // down arrow
      return active - 1 === filtered.length ? null : setActive(active + 1);
    }
  };

  const inputClasses = classNames(css.inputWrapper, {
    [css.inputError]: error,
  });

  const renderAutocomplete = () => {
    if (isShow) {
      if (loadingResults) {
        return (
          <div className={css.noAutoComplete}>
            <em>Loading...</em>
          </div>
        );
      } else {
        if (filtered.length) {
          return (
            <ul className={css.autocomplete}>
              {filtered.map((suggestion, index) => {
                let className;
                if (index === active) {
                  className = css.active;
                }
                function generateRandomInteger(max) {
                  return Math.floor(Math.random() * max) + 1;
                }

                const foundSuggestion = fullInfoSuggestions.find(
                  s => s[labelPropName] === suggestion
                );
                return (
                  <li
                    className={className}
                    key={
                      foundSuggestion?.id
                        ? foundSuggestion?.id + generateRandomInteger(999999999)
                        : generateRandomInteger(999999999)
                    }
                    onClick={onClick}
                  >
                    {suggestion}
                  </li>
                );
              })}
            </ul>
          );
        } else {
          return (
            <div className={`${css.noAutocomplete} w-full`}>
              <em>
                Not found.
              </em>
            </div>
          );
        }
      }
    }
    return null;
  };

  return (
    <div className={css.inputWrapper} ref={ref}>
      <input
        className={inputClasses}
        id={'autocomplete-field'}
        type="text"
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={input}
        placeholder={placeholder}
      />
      {renderAutocomplete()}
      <ValidationError fieldMeta={{ touched, error: error ? 'Required' : '' }} />
    </div>
  );
};

export default AutoCompleteField;
