import React from 'react';
import cls from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as Framer from 'framer-motion';
import * as RadixToast from '@radix-ui/react-toast';
import { func, node, number, string } from 'prop-types';

const DURATION = 8000;
const Toast = props => {
  const { className, variant, title, description, duration, onClick } = props;
  const [open, setOpen] = React.useState(true);
  const variants = React.useMemo(
    () => ({
      'text-white bg-[#FF370B]': variant === 'error',
      'text-white bg-[#7ABADF]': variant === 'info',
    }),
    [variant]
  );

  return (
    <Framer.AnimatePresence>
      {open && (
        <RadixToast.Root asChild duration={duration} onOpenChange={setOpen} open={open}>
          <Framer.motion.div
            className={cls(
              'w-80 md:w-140 flex flex-col rounded-xl p-4 cursor-pointer',
              variants,
              className
            )}
            onClick={onClick}
            initial={{ opacity: 0, x: '20%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: '20%' }}
          >
            <RadixToast.Title asChild>
              <h5 className={'uppercase font-medium m-0 mb-2'}>{title}</h5>
            </RadixToast.Title>
            <div className={'max-h-40 overflow-y-scroll overflow-x-hidden'}>
              <RadixToast.Description asChild>
                <p className={'m-0'}>{description}</p>
              </RadixToast.Description>
            </div>
          </Framer.motion.div>
        </RadixToast.Root>
      )}
    </Framer.AnimatePresence>
  );
};

Toast.defaultProps = {
  variant: 'error',
  duration: DURATION,
};

Toast.propTypes = {
  className: string,
  variant: string,
  title: node.isRequired,
  description: node.isRequired,
  duration: number,
  onClick: func,
};

const Toasts = props => {
  const { className, toasts } = props;

  return (
    <RadixToast.Viewport
      className={cls(
        'z-3 fixed top-4 right-4 flex flex-col gap-y-4 md:top-[unset] md:bottom-4',
        className
      )}
    >
      {toasts.map((toast, index) => {
        return <Toast key={index} {...toast} />;
      })}
    </RadixToast.Viewport>
  );
};

Toasts.defaultProps = {};
Toasts.propTypes = {
  className: string,
};

const initialState = [];

const ADD_TOAST = 'app/ADD_TOAST';
const RESET_TOASTS = 'app/RESET_TOASTS';

const addToast = toast => ({ type: ADD_TOAST, payload: toast });
const resetToasts = () => ({ type: RESET_TOASTS });

export function toastsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADD_TOAST: {
      return [...state, payload];
    }
    case RESET_TOASTS: {
      return [];
    }
    default: {
      return state;
    }
  }
}

// const ToastsContext = React.createContext([]);
const RadixToastsProvider = props => {
  const { children, toasts, addToast, resetToasts } = props;
  /* Reset toasts on render */
  React.useEffect(() => {
    resetToasts();
  }, []);

  return (
    <RadixToast.ToastProvider>
      <>{children(toasts, addToast)}</>
      <Toasts toasts={toasts} />
    </RadixToast.ToastProvider>
  );

  // return (
  //   <ToastsContext.Provider value={[toasts, addToast]}>
  //     <RadixToast.ToastProvider>
  //       {children(toasts, addToast)}
  //       <Toasts toasts={toasts} />
  //     </RadixToast.ToastProvider>
  //   </ToastsContext.Provider>
  // );
};

RadixToastsProvider.defaultProps = {};
RadixToastsProvider.propTypes = {
  children: func,
};

export const ToastsProvider = compose(
  connect(
    state => ({
      toasts: state.toasts,
    }),
    dispatch => ({
      addToast: toast => dispatch(addToast(toast)),
      resetToasts: () => dispatch(resetToasts()),
    })
  )
)(RadixToastsProvider);

// function useToasts() {
//   return React.useContext(ToastsContext);
// }

// export function useToast() {
//   const [toasts, addToast] = useToasts();
//   return toast => {
//     addToast([...toasts, toast]);
//   };
// }

export function withToasts(Component) {
  const displayName = Component.displayName || Component.name || 'Component';
  const ComponentWithToasts = props => {
    return (
      <ToastsProvider>
        {(toasts, addToast) => {
          const toast = toast => addToast(toast);
          return <Component {...props} toast={toast} />;
        }}
      </ToastsProvider>
    );
  };

  ComponentWithToasts.displayName = `withToasts(${displayName})`;

  return ComponentWithToasts;
}
