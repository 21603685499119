import React from 'react';
import PropTypes from 'prop-types';

import { useConfiguration } from '../../context/configurationContext';
import { ResponsiveImage } from '../../components/';
import logoPath from '../../assets/Bazaa_Logo Full Colour-Reverse_transperant.png';


const Logo = props => {
  const config = useConfiguration();
  const { className, format, ...rest } = props;

  return <img className={className} src={logoPath} alt={config.marketplaceName} {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
