import React, { Component } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

const FieldDimensionNumberInputComponent = props => {
  const {
    rootClassName,
    className,
    inputClass,
    customErrorText,
    id,
    label,
    input,
    onTouch,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    hideErrorMessage,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!customErrorText || !!(touched && invalid && error);

  const fieldMeta = { touched: hasError, error: errorText };

  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses = inputClass;

  const inputProps = isUncontrolled
    ? {
      className: inputClasses,
      id,
      type: 'number',
      defaultValue,
      ...refMaybe,
      ...inputWithoutValue,
      ...rest,
    }
    : { className: inputClasses, id, type: 'number', ...refMaybe, ...input, ...rest };

  const classes = classNames(rootClassName, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <input {...inputProps} onFocus={onTouch} />
      {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

FieldDimensionNumberInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

class FieldDimensionNumberInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldDimensionNumberInputComponent} {...this.props} />;
  }
}

export default FieldDimensionNumberInput;