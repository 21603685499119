import React from 'react';
import PropTypes from 'prop-types';
import { NamedLink } from '../../components';

const Tab = props => {
  const { id, text, selected, linkProps } = props;

  return (
    <NamedLink id={id} className={`${selected ? 'md:bg-fawn before:md:w-[5px] before:w-full before:md:h-full before:h-[2px] before:absolute before:content-[""] before:block before:md:top-0 before:bottom-0 before:bg-bazaa' : ''} hover:md:bg-fawn py-3 md:py-4 block md:w-full uppercase font-bold text-sm relative`} {...linkProps}>
      <span className="md:ml-10 whitespace-nowrap">{text}</span>
    </NamedLink>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
};

const TabNav = props => {
  const { tabs } = props;
  return (
    <nav className="w-full md:max-w-[250px] md:min-w-[250px] md:bg-light-fawn pt-2 shadow-text-field md:pt-20 px-6 md:px-0 flex flex-row md:flex-col gap-6 md:gap-0 md:min-h-screen">
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return (
          <React.Fragment key={id}>
            {index === 0 && <hr className="hidden md:block w-full h-[1px] bg-fawn my-0 !border-none" />}
            <Tab id={id} {...tab} />
            <hr className="hidden md:block w-full h-[1px] bg-fawn my-0 !border-none" />
          </React.Fragment>
        );
      })}
    </nav>
  );
};

TabNav.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNav.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
};

export default TabNav;
